import { Component, Input } from '@angular/core';

@Component({
	selector: 'ui-icon',
	templateUrl: './icon.component.html',
})
export class IconComponent {
	@Input({ required: true }) name!: string;
	@Input() outlined?: boolean;
}
